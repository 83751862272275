<template>
  <div>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label-for="weighing"
      class="label m-0"
    >
      <template #label>
        <div class="weighing-label">
          <div class="label-content">Ingresar datos por:</div>
        </div>
      </template>
      <div id="toggle_in_weeks">
        <div class="d-flex">
          <div class="toggle-types noprint">
            <span
              :class="{
                'font-weight-bold': egressProfileMatter.toggle_in_weeks,
              }"
              >Semana</span
            >
            <b-button
              class="check-type"
              variant="primary"
              :class="{ 'check-type-disabled': egressProfileMatter.is_closed }"
              @click="changeToggleInWeeks"
            >
              <!-- @click="
                () => {
                  egressProfileMatter.toggle_in_weeks =
                    !egressProfileMatter.toggle_in_weeks;
                }
              " -->
              <b-icon
                icon="circle-fill"
                class="icon-check"
                :class="{
                  'icon-check-active': !egressProfileMatter.toggle_in_weeks,
                }"
                scale="0.7"
              ></b-icon>
            </b-button>
            <span
              :class="{
                'font-weight-bold': !egressProfileMatter.toggle_in_weeks,
              }"
              >Total</span
            >
          </div>
        </div>
      </div>
    </b-form-group>
    <!-- Horas Pedagógicas -->
    <b-table-simple
      borderless
      small
      responsive
      v-if="
        egressProfileMatterHoursgFilter.filter(
          (x) => x.uses_module_minutes == true
        ).length > 0
      "
    >
      <b-thead>
        <b-th class="w-25">Horas Pedagógicas:</b-th>
        <b-th class="w-75"></b-th>
        <b-th class="text-center">
          <div>
            +
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Incluir en ${$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )}`"
            >
            </InfoTooltip>
          </div>
        </b-th>
        <b-th class="text-center">
          <div>
            %
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Porcentaje de asistencia requerido`"
            >
            </InfoTooltip>
          </div>
        </b-th>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="hour in egressProfileMatterHoursgFilter.filter(
            (x) => x.uses_module_minutes == true
          )"
          :key="hour.id"
        >
          <b-td class="p-0">
            <b-form-group
              :label="hour.name"
              :label-for="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              label-class="label-matter mt-2"
            ></b-form-group>
            <!-- <div class="mt-1 label-matter">{{ hour.name }}:</div> -->
          </b-td>
          <b-td class="p-0">
            <b-form-input
              v-if="egressProfileMatter.toggle_in_weeks"
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.tem_hours"
              :disabled="egressProfileMatter.is_closed"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.tem_hours = 0;
                  else if (parseFloat(value) >= 0)
                    hour.hours =
                      parseFloat(value) * parseFloat(egressProfileMatter.weeks);
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-input
              v-else
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.hours"
              :disabled="egressProfileMatter.is_closed"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.hours = 0;
                  else if (parseFloat(value) >= 0) {
                    if (
                      egressProfileMatter.weeks == 0 ||
                      parseFloat(value) == 0
                    )
                      hour.tem_hours = 0;
                    else
                      hour.tem_hours = parseFloat(
                        (
                          parseFloat(value) /
                          parseFloat(egressProfileMatter.weeks)
                        ).toFixed(2)
                      );
                  }
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            >
          </b-td>
          <b-td class="p-0">
            <b-form-checkbox
              :id="`checkbox-${hour.uses_module_minutes}-input-${hour.id}`"
              class="mt-2 ml-2"
              size="lg"
              v-model="hour.counts_towards_credits"
              :disabled="egressProfileMatter.is_closed"
            ></b-form-checkbox>
          </b-td>
          <b-td class="p-0"
            ><b-form-input
              :id="`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.attendance_requirement"
              :disabled="egressProfileMatter.is_closed"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.attendance_requirement = 0;
                  else if (parseFloat(value) > 100)
                    hour.attendance_requirement = 100;
                }
              "
              style="min-width: 4rem"
              :aria-describedby="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            ></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label-matter mb-0 mx-0"
    >
      <template #label>
        <div>
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            )
          }}
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
        >
          {{ totalPedagogical | Round }}
        </b-badge>
      </div>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label mb-0 mx-0"
    >
      <template #label>
        <div>
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours_to_chronological",
              true,
              "Total de Horas Pedagógicas (En Cronológicas)"
            )
          }}
          <span>
            <InfoTooltip
              v-if="profileType"
              class="info-icon"
              :tooltip_text="`${$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )}(${totalPedagogical}) * Cantidad de Minutos por Hora Pedagógica(${
                profileType ? profileType.module_minutes : 0
              }/60)= ${totalPedagogicalToChronological}`"
            >
            </InfoTooltip>
          </span>
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
          v-if="profileType"
        >
          {{ totalPedagogicalToChronological }}
        </b-badge>
      </div>
    </b-form-group>
    <!-- Horas Cronológicas -->
    <b-table-simple
      borderless
      small
      responsive
      v-if="
        egressProfileMatterHoursgFilter.filter(
          (x) => x.uses_module_minutes == false
        ).length > 0
      "
    >
      <b-thead>
        <b-th class="w-25">Horas Cronológicas:</b-th>
        <b-th class="w-75"></b-th>
        <b-th class="text-center">
          <div>
            +
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Incluir en ${$getVisibleNames(
                'manual.total_chronological_hours',
                true,
                'Total de Horas Cronológicas'
              )}`"
            >
            </InfoTooltip>
          </div>
        </b-th>
        <b-th class="text-center">
          <div>
            %
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Porcentaje de asistencia requerido`"
            >
            </InfoTooltip>
          </div>
        </b-th>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="hour in egressProfileMatterHoursgFilter.filter(
            (x) => x.uses_module_minutes == false
          )"
          :key="hour.id"
        >
          <b-td class="p-0">
            <b-form-group
              :label="hour.name"
              :label-for="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              label-class="label-matter mt-2"
            ></b-form-group>
          </b-td>
          <b-td class="p-0">
            <b-form-input
              v-if="egressProfileMatter.toggle_in_weeks"
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.tem_hours"
              :disabled="egressProfileMatter.is_closed"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.tem_hours = 0;
                  else if (parseFloat(value) >= 0)
                    hour.hours =
                      parseFloat(value) * parseFloat(egressProfileMatter.weeks);
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-input
              v-else
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.hours"
              :disabled="egressProfileMatter.is_closed"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.hours = 0;
                  else if (parseFloat(value) >= 0) {
                    if (
                      egressProfileMatter.weeks == 0 ||
                      parseFloat(value) == 0
                    )
                      hour.tem_hours = 0;
                    else
                      hour.tem_hours = parseFloat(
                        (
                          parseFloat(value) /
                          parseFloat(egressProfileMatter.weeks)
                        ).toFixed(2)
                      );
                  }
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            >
          </b-td>
          <b-td class="p-0">
            <b-form-checkbox
              :id="`checkbox-${hour.uses_module_minutes}-input-${hour.id}`"
              class="mt-2 ml-2"
              size="lg"
              v-model="hour.counts_towards_credits"
              :disabled="egressProfileMatter.is_closed"
            ></b-form-checkbox>
          </b-td>
          <b-td class="p-0">
            <b-form-input
              :id="`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.attendance_requirement"
              :disabled="egressProfileMatter.is_closed"
              style="min-width: 4rem"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.attendance_requirement = 0;
                  else if (parseFloat(value) > 100)
                    hour.attendance_requirement = 100;
                }
              "
              :aria-describedby="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            ></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label mb-0 mx-0"
    >
      <template #label>
        <div>
          {{
            $getVisibleNames(
              "manual.total_chronological_hours",
              true,
              "Total de Horas Cronológicas"
            )
          }}
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
        >
          {{ totalChronological | Round }}
        </b-badge>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "TimeAllocationComponent",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: true,
    },
    // matterHours: {
    //   type: Array,
    // },
    egressProfileMatter: {
      type: Object,
    },
    profileType: {
      type: Object,
    },
  },
  data() {
    return {
      matter_hours: [],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      time_allocations: names.TIME_ALLOCATIONS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
    }),
    timeAllocationsIds() {
      const time_allocation_ids = [
        ...new Set(
          this.studyEnvironmentsTimeAllocation
            .filter((x) =>
              this.egressProfileMatter.study_environments.includes(
                x.study_environment
              )
            )
            .map((x) => x.time_allocation)
        ),
      ];
      return time_allocation_ids;
    },
    egressProfileMatterHoursgFilter() {
      if (
        this.egressProfileMatter &&
        this.egressProfileMatter.study_environments.length > 0
      )
        return this.matter_hours.filter(
          (x) =>
            this.timeAllocationsIds.includes(x.time_allocation) ||
            !x.has_study_environments ||
            x.hours > 0 ||
            x.tem_hours > 0
        );
      else return this.matter_hours;
    },
    totalPedagogical() {
      let count = 0;
      this.matter_hours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            // if (this.egressProfileMatter.toggle_in_weeks)
            //   count +=
            //     parseFloat(element.hours) *
            //     parseFloat(this.egressProfileMatter.weeks);
            // else
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    totalPedagogicalToChronological() {
      if (!this.profileType) return 0;
      else {
        if (
          this.institution &&
          parseInt(this.institution.hour_decimal_places) === 0
        ) {
          return parseFloat(
            Math.round(
              this.totalPedagogical *
                (parseFloat(this.profileType.module_minutes) / 60)
            )
          );
        } else
          return parseFloat(
            (
              this.totalPedagogical *
              (parseFloat(this.profileType.module_minutes) / 60)
            ).toFixed(
              this.institution &&
                parseInt(this.institution.hour_decimal_places) > 0
                ? parseInt(this.institution.hour_decimal_places)
                : 2
            )
          );
      }
    },
    totalChronological() {
      let count = 0;
      this.matter_hours
        .filter((x) => x.uses_module_minutes == false)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            // if (this.egressProfileMatter.toggle_in_weeks)
            //   count +=
            //     parseFloat(element.hours) *
            //     parseFloat(this.egressProfileMatter.weeks);
            // else
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
  },
  methods: {
    changeToggleInWeeks() {
      if (!this.egressProfileMatter.is_closed)
        this.egressProfileMatter.toggle_in_weeks =
          !this.egressProfileMatter.toggle_in_weeks;
      //   if (this.egressProfileMatter.toggle_in_weeks)
      //     this.matter_hours.forEach((x, index) => {
      //       if (parseFloat(this.egressProfileMatter.weeks) <= 0) x.tem_hours = 0;
      //       else
      //         x.tem_hours = Math.round(
      //           parseInt(x.hours) / parseInt(this.egressProfileMatter.weeks)
      //         );
      //       if (this.matter_hours.length == index + 1)
      //         this.$emit("updated", this.matter_hours);
      //     });
    },
  },
  mounted() {
    this.matter_hours = [];
    if (isNaN(this.egressProfileMatter.id)) {
      if (this.institution && this.institution.internal_use_id != "duoc_uc")
        this.egressProfileMatter.toggle_in_weeks = true;
      this.time_allocations.forEach((element, index) => {
        this.matter_hours.push({
          id: generateUniqueId(),
          time_allocation: element.id,
          name: element.name,
          order: element.order,
          egress_profile_matter: generateUniqueId(),
          counts_towards_credits: element.counts_towards_credits,
          has_study_environments: element.has_study_environments,
          uses_module_minutes: element.uses_module_minutes,
          attendance_requirement: element.attendance_requirement
            ? element.attendance_requirement
            : 0,
          hours: 0,
          tem_hours: 0,
        });
        if (this.time_allocations.length == index + 1)
          this.$emit("updated", this.matter_hours);
      });
    } else if (!isNaN(this.egressProfileMatter.id)) {
      this.$store
        .dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
          egress_profile_matter_id: this.egressProfileMatter.id,
        })
        .then((response) => {
          this.time_allocations.forEach((element, index) => {
            const matter_hour = response.find(
              (x) =>
                x.egress_profile_matter == this.egressProfileMatter.id &&
                x.time_allocation == element.id
            );
            if (matter_hour)
              this.matter_hours.push({
                id: matter_hour.id,
                time_allocation: matter_hour.time_allocation,
                name: element.name,
                order: element.order,
                uses_module_minutes: element.uses_module_minutes,
                has_study_environments: element.has_study_environments,
                egress_profile_matter: matter_hour.egress_profile_matter,
                counts_towards_credits: matter_hour.counts_towards_credits,
                attendance_requirement: matter_hour.attendance_requirement,
                hours: matter_hour.hours,
                tem_hours:
                  parseFloat(this.egressProfileMatter.weeks) > 0
                    ? parseFloat(
                        (
                          parseFloat(matter_hour.hours) /
                          parseFloat(this.egressProfileMatter.weeks)
                        ).toFixed(2)
                      )
                    : 0,
              });
            else
              this.matter_hours.push({
                id: generateUniqueId(),
                time_allocation: element.id,
                name: element.name,
                order: element.order,
                egress_profile_matter: this.egressProfileMatter.id,
                counts_towards_credits: element.counts_towards_credits,
                has_study_environments: element.has_study_environments,
                uses_module_minutes: element.uses_module_minutes,
                attendance_requirement: element.attendance_requirement
                  ? element.attendance_requirement
                  : 0,
                hours: 0,
                tem_hours: 0,
              });
            if (this.time_allocations.length == index + 1)
              this.$emit("updated", this.matter_hours);
          });
        });
    }
  },
  watch: {
    "egressProfileMatter.weeks": function () {
      this.matter_hours.forEach((x) => {
        if (
          this.egressProfileMatter.weeks == 0 ||
          this.egressProfileMatter.weeks == "" ||
          this.egressProfileMatter.weeks == null
        ) {
          x.tem_hours = 0;
          x.hours = 0;
        } else {
          x.hours =
            parseFloat(x.tem_hours) *
            parseFloat(this.egressProfileMatter.weeks);
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped>
.toggle-types {
  display: flex;
  float: right;
  /* margin-right: 1em; */
  /* background: rgb(230, 230, 230); */
  padding: 0.3em 1.8em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-69%, -50%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
.form-group >>> .label-matter {
  margin-top: 0.2rem;
  padding-right: 10px !important;
  font-size: var(--secondary-font-size);
}
#total_hours {
  font-size: var(--primary-font-size);
}
.total-hours-value {
  margin-left: 0.1em !important;
  font-weight: normal;
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
@media (max-width: 991px) {
  .info-icon {
    margin-left: 0;
  }
}
</style>